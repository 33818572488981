<template>
  <div class="public-agencies-page">
    <div
      v-if="$can('create', 'licensingagency')"
      class="btn-actions-content"
    >
      <b-button
        class="btn-grad d-flex align-items-center"
        @click="openModal()"
      >
        <VSIcons
          name="AddSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span class="align-middle">Adicionar</span>
      </b-button>
    </div>
    <b-input-group class="input-group-merge round search-input-filter">
      <b-input-group-prepend
        is-text
      >
        <VSIcons
          name="Search"
          color="#7D8E9E"
          width="18"
          height="18"
        />
      </b-input-group-prepend>
      <b-form-input
        id="filter-input"
        v-model="filter"
        placeholder="Buscar..."
        type="search"
      />
    </b-input-group>
    <b-table
      id="public-agencies-table"
      ref="publicAgenciesTable"
      class="module-table mt-4"
      :items="items"
      :fields="fields"
      hover
      responsive
      details-td-class="align-middle"
      :busy="apiInCall"
      :per-page="perPage"
      :current-page="currentPage"
      selectable
      :filter="filter"
      :filter-included-fields="filterOn"
      empty-filtered-text="Não há resultados para sua pesquisa."
      empty-text="Não há registros para serem exibidos."
      show-empty
      @filtered="onFiltered"
      @row-selected="detailItemTable"
    >
      <template #table-busy>
        <b-spinner variant="primary" />
      </template>
      <template
        v-if="$can('delete', 'licensingagency')"
        #head(select)
      >
        <b-form-checkbox
          v-model="allSelected"
          :indeterminate="indeterminate"
          @change="toggleAll('system/licensing-agency', 'LicensingAgency')"
        />
      </template>
      <template
        v-if="$can('delete', 'licensingagency')"
        #cell(select)="row"
      >
        <b-form-checkbox
          v-model="selected"
          :value="row.item"
          @change="setItensToDelete({ itens: selected, api: 'system/licensing-agency', fetchTable: 'LicensingAgency' })"
        />
      </template>
      <template #cell(cnpj)="row">
        {{ formatCpfCnpj(row.item.cnpj) }}
      </template>
      <template #cell(address)="row">
        {{ `${row.item.address}, ${row.item.number_address}` }}
      </template>
      <template #cell(license_type)="row">
        {{ row.item.license_type.length }}
        <VSIcons
          class="ml-1"
          name="TaskSquareFillGradient"
        />
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="public-agencies-table"
    >
      <template #prev-text>
        <VSIcons name="ArrowLeft" />
      </template>
      <template #next-text>
        <VSIcons name="ArrowRight" />
      </template>
    </b-pagination>
    <VSModal
      width="500"
      name="Import"
      :draggable="false"
    />
  </div>
</template>

<script>
import {
  BInputGroup, BInputGroupPrepend, BFormInput, BTable, BButton, BFormCheckbox, BSpinner, BPagination,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { tableConfig } from '@core/mixins/ui/forms'
import VSIcons from '@/assets/icons/VSIcons.vue'
import VSModal from '@/components/modals/VSModal.vue'

export default {
  components: {
    BInputGroup, BInputGroupPrepend, BFormInput, BTable, BButton, VSIcons, BFormCheckbox, BSpinner, BPagination, VSModal,
  },
  mixins: [
    tableConfig,
  ],
  data() {
    return {
      selected: [],
      perPage: 20,
      currentPage: 1,
      fields: [
        {
          label: '',
          key: 'select',
        },
        {
          label: 'Nome',
          key: 'name',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Sigla',
          key: 'acronyms',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'CNPJ',
          key: 'cnpj',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Competência',
          key: 'level',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Endereço',
          key: 'address',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Tipo de licença',
          key: 'license_type',
          sortable: true,
          tdClass: 'align-middle j-c-center to-uppercase',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ apiInCall: 'app/getApiInCall', items: 'publicAgencies/getLicensingAgencies' }),
  },
  created() {
    this.fetchLicensingAgencies()
  },
  methods: {
    ...mapActions({ fetchLicensingAgencies: 'publicAgencies/fetchLicensingAgencies' }),
    ...mapMutations({
      setItensToDelete: 'app/SET_ITENS_DELETE',
    }),
    createPublicAgencies() {
      this.$router.push('orgaos-publicos/adicionar/detalhes')
    },
    openModal() {
      this.showModal('Import')
    },
    formatCpfCnpj(value) {
      if (value && value.length === 14) {
        return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
      }
      return value
    },
  },
}
</script>
