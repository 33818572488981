<template>
  <div><PublicAgencies /></div>
</template>

<script>
import PublicAgencies from '@/modules/public-agencies/PublicAgencies.vue'

export default {
  components: {
    PublicAgencies,
  },
}
</script>

<style>

</style>
